.body {
  min-height: 100vh;
}

.header {
  width: 100vw;
  height: auto;
  object-fit: fill;
}

.titleBox {
  display: flex;
  justify-content: space-evenly;
}

.left {
  width: 140px;
  height: auto;
  align-self: left;
}

.right {
  width: 140px;
  height: auto;
  justify-self: right;
}

.title {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  color: #f0608f;
}

.text {
  color: #979797;
  font-size: 17px;
  margin: 50px;
  margin-top: 0px;
}

.title2 {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  color: #f0608f;
  margin-top: 10vw;
}

.footerBox {
  position: absolute;
}

.footer {
  width: 100vw;
  height: 40vw;
  object-fit: fill;
  margin-top: 5vw;
  margin-bottom: -2vw;
}

.box {
  position: absolute;
  left: 10vw;
  bottom: 5vw;
}

.logo {
  width: 28vw;
  height: auto;
  margin-left: -3vw;
}

.appstoreFooter {
  width: 17vw;
  height: auto;
  position: absolute;
  right: 10vw;
  top: 15vw;
  cursor: pointer;
}

.playstoreFooter {
  width: 17vw;
  height: auto;
  position: absolute;
  right: 10vw;
  top: 23vw;
  cursor: pointer;
}

.stores {
  margin-top: -1.5vw;
  margin-bottom: 15vw;
  text-align: center;
}

.appstore {
  width: 30vw;
  height: auto;
  margin-right: 25px;
  cursor: pointer;
}

.playstore {
  width: 30vw;
  cursor: pointer;
}

@media only screen and (min-width: 700px) {
  .appstore {
    width: 20vw;
  }

  .playstore {
    width: 20vw;
  }
}

a {
  text-decoration: none;
  color: white;
  font-size: 12px;
}

.copyright {
  font-size: 8.5px;
  color: white;
  left: 35vw;
  bottom: 0px;
  position: absolute;
}

@media only screen and (min-width: 1000px) {
  .title {
    font-size: 65px;
  }
  .title2 {
    font-size: 65px;
  }

  .subTitle {
    font-size: 35px;
  }

  .text {
    font-size: 30px;
    margin: 190px;
    margin-top: 0px;
  }

  a {
    font-size: 25px;
  }

  .copyright {
    font-size: 22px;
  }

  .left {
    width: 20vw;
  }

  .right {
    width: 20vw;
  }

  .footer {
    height: 30vw;
  }

  .appstoreFooter {
    width: 13vw;
  }

  .playstoreFooter {
    width: 13vw;
  }
}
