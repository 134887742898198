.body {
  display: flex;

  justify-content: center;
  background-color: #bc8497;
  min-height: 100vh;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.lottie {
  margin-top: 200px;
}

.notFound {
  color: white;
  font-size: 25px;
  font-weight: bold;
  margin-top: 150px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.box {
  width: 80vw;
  margin-top: 150px;
  padding: 15px;
  border-color: #435a76;
}

@media only screen and (min-width: 1000px) {
  .box {
    width: 50vw;
  }
}

.header {
  /* background-color: #435a76; */

  color: white;
  padding: 15px 0px 10px 15px;
  font-size: 14px;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 40%);
}

.image {
  width: 45px;
  height: 30px;
  margin-right: 20px;
}

.textarea {
  width: 100%;
  resize: vertical;
  padding: 15px;
  box-sizing: border-box;
  font-size: 17px;
  margin: -2px 0px 0px 0px;
  border-radius: 0px 0px 20px 20px;
  border: none;
  outline: none;
  box-shadow: 5px 10px 10px rgb(0 0 0 / 40%);
}

.boxBtn {
  height: 65px;
}

.sendBtn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  background-color: #15202c;
  border-radius: 40px;
  border-color: #15202c;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 40%);
  transition: 0.5s ease all;
  cursor: pointer;
}

.getAppBtn {
  width: 100%;
  padding: 15px;
  color: white;
  background-color: #15202c;
  border-radius: 40px;
  border-color: #15202c;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 40%);
  transition: 0.5s ease all;
  cursor: pointer;
  animation: buttonBeat 1.5s ease-in-out infinite;
}

.textBtn {
  font-size: 19px;
  font-weight: bold;
}

@keyframes buttonBeat {
  0% {
    transform: scale(0.98);
    box-shadow: 0px 0px 1px #435a76;
  }
  50% {
    transform: scale(1.03);
    box-shadow: 0px 0px 15px #627080;
  }
  100% {
    transform: scale(0.98);
    box-shadow: 0px 0px 1px #435a76;
  }
}

.sendBtn:hover {
  color: #15202c;
  background-color: #ffeaea;
}

.boxMsg {
  height: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msgTxt {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.invalidMsg {
  color: rgb(165, 33, 33);
  font-size: 16px;
  font-weight: bold;
}
